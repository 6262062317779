import { motion } from 'framer-motion';

const TokenPage = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-40">
      <div className="max-w-7xl mx-auto px-4">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-40"
        >
          <h1 className="text-5xl sm:text-6xl md:text-[7rem] font-black mb-8">$degs</h1>
          <p className="text-3xl md:text-4xl text-white">
            powering the <span className="text-emerald-400 font-bold">future</span> of game creation
          </p>
        </motion.div>

        {/* Supply & Network Info */}
        <div className="relative z-10 mb-40">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-start gap-16">
              <div className="flex-1">
                <h2 className="text-5xl md:text-7xl font-black mb-8">1 Billion</h2>
                <div className="space-y-4 text-2xl md:text-3xl">
                  <p>total token supply</p>
                  <p>fixed forever</p>
                  <p className="text-emerald-400 font-bold">zero inflation.</p>
                </div>
              </div>
              <div className="flex-1">
                <h2 className="text-5xl md:text-7xl font-black mb-8">Base Chain</h2>
                <div className="space-y-4 text-2xl md:text-3xl">
                  <p>low Fees</p>
                  <p>ERC-20 standard</p>
                  <p className="text-emerald-400 font-bold">maximum compatibility.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Distribution with Visual Elements */}
        <div className="relative mb-40">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-5xl md:text-7xl font-black mb-16">distribution</h2>
            
            {/* Fair Launch - Featured with Breakdown */}
            <div className="mb-16 p-8 bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20">
              {/* Main Fair Launch Info */}
              <div className="flex flex-col md:flex-row items-start gap-8 mb-16 pb-8 border-b border-emerald-500/20">
                <div className="flex-1">
                  <h3 className="text-4xl md:text-6xl font-black mb-4">fair launch</h3>
                  <p className="text-7xl md:text-8xl font-black text-emerald-400 mb-4">90%</p>
                  <p className="text-2xl md:text-3xl">of total supply</p>
                </div>
                <div className="flex-1 text-2xl md:text-3xl">
                  <p className="text-emerald-400 font-bold mb-6 text-4xl md:text-5xl">no bullshit.</p>
                  <div className="text-2xl md:text-3xl space-y-4">
                    <p>PancakeSwap SpringBoard</p>
                    <p>100 ETH target</p>
                    <p>no team allocation</p>
                  </div>
                </div>
              </div>

              <p className="text-xl md:text-2xl mb-8 text-emerald-400/80">Composed of</p>

              {/* Breakdown */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Public Sale */}
                <div className="p-8 bg-black/30 rounded-2xl">
                  <h3 className="text-3xl md:text-4xl font-black mb-4">sale</h3>
                  <p className="text-5xl md:text-6xl font-black text-emerald-400 mb-4">75%</p>
                  <p className="text-xl md:text-2xl mb-4">of total supply</p>
                  <p className="text-emerald-400 font-bold">community owned.</p>
                </div>

                {/* Liquidity */}
                <div className="p-8 bg-black/30 rounded-2xl">
                  <h3 className="text-3xl md:text-4xl font-black mb-4">liquidity</h3>
                  <p className="text-5xl md:text-6xl font-black text-emerald-400 mb-4">15%</p>
                  <p className="text-xl md:text-2xl mb-4">of total supply</p>
                  <div className="space-y-2">
                    <p className="text-emerald-400 font-bold">locked forever with the raised sale funds.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Reserved */}
            <div className="p-8 bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20">
              <div className="flex flex-col md:flex-row items-start gap-8">
                <div className="flex-1">
                  <h3 className="text-4xl md:text-6xl font-black mb-4">marketing</h3>
                  <p className="text-7xl md:text-8xl font-black text-emerald-400 mb-4">10%</p>
                  <p className="text-2xl md:text-3xl">of total supply</p>
                </div>
                <div className="flex-1 text-2xl md:text-3xl">
                  <p className="text-emerald-400 font-bold mb-6 text-4xl md:text-5xl">strategic growth.</p>
                  <div className="text-2xl md:text-3xl space-y-4">
                    <p>Marketing campaigns</p>
                    <p>Community contests</p>
                    <p>3-month vesting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Utility Section */}
        <div className="relative mb-40">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-5xl md:text-7xl font-black mb-16">utility</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Revenue Share */}
              <div className="p-8 bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20">
                <h3 className="text-3xl md:text-4xl font-black mb-4">revenue share</h3>
                <div className="flex items-baseline gap-2 mb-4">
                  <p className="text-5xl md:text-6xl font-black text-emerald-400">100%</p>
                  <p className="text-xl md:text-2xl">of the platform earnings to holders</p>
                </div>
                <p className="text-xl md:text-2xl text-white/80">All revenues from the ads + the 30% of the revenue from the games</p>
              </div>

              {/* Platform Currency */}
              <div className="p-8 bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20">
                <h3 className="text-3xl md:text-4xl font-black mb-4">platform use</h3>
                <div className="space-y-4 text-xl md:text-2xl">
                  <p>Powers all platform features:</p>
                  <p>• In-app purchases</p>
                  <p>• Advertisements</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenPage; 