import { motion } from 'framer-motion';
import { navigateExternal } from '../utils/navigation';

const SocialsPage = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-40">
      <div className="max-w-7xl mx-auto px-4">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-40"
        >
          <h1 className="text-5xl sm:text-6xl md:text-[7rem] font-black mb-8">socials</h1>
          <p className="text-3xl md:text-4xl text-white">
            join the <span className="text-emerald-400 font-bold">underdogs</span>
          </p>
        </motion.div>

        {/* Links Grid */}
        <section className="mb-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32">
            <div>
              <div className="mb-16">
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">twitter</h3>
              </div>
              <div className="space-y-8">
                <div className="space-y-4 text-3xl md:text-4xl text-white mb-8">
                  <p>announcements</p>
                  <p>community updates</p>
                </div>
                <button 
                  onClick={() => navigateExternal('https://x.com/degs_ai')}
                  className="bg-emerald-500 hover:bg-emerald-400 text-black font-bold text-2xl md:text-3xl px-12 py-6 rounded-2xl transition-all hover:scale-105"
                >
                  follow us
                </button>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">telegram</h3>
              </div>
              <div className="space-y-8">
                <div className="space-y-4 text-3xl md:text-4xl text-white mb-8">
                  <p>meet the community</p>
                  <p>share your ideas</p>
                </div>
                <button 
                  onClick={() => navigateExternal('https://t.me/degs_ai')}
                  className="bg-emerald-500 hover:bg-emerald-400 text-black font-bold text-2xl md:text-3xl px-12 py-6 rounded-2xl transition-all hover:scale-105"
                >
                  join us
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SocialsPage; 