import { motion } from 'framer-motion';
import { Tweet } from 'react-tweet';

interface ManifestoContentProps {
  activeSection: string;
  activeSubSection: string;
}

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const ManifestoContent = ({ activeSection, activeSubSection }: ManifestoContentProps) => {
  return (
    <div className="flex-1 md:ml-64 p-8 pt-24 bg-gradient-to-b from-black via-black to-black/95">
      <motion.div 
        className="max-w-3xl mx-auto"
        initial="initial"
        animate="animate"
        variants={staggerChildren}
      >
        {/* Manifesto Section */}
        {activeSection === 'manifesto' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Manifesto
            </motion.h1>
            
            <section id="the-problem-gaming-is-broken" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                gaming is broken
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  the gaming industry has become a <span className="text-emerald-400 font-bold">$300B+ dystopia.</span> a handful of corporations dictate what gets created, what stories get told, and who gets to tell them. they've turned gaming into a soulless machine of profit extraction.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  remember when games were about <span className="text-emerald-400 font-bold">creativity?</span> when developers took risks? when microtransactions didn't infest every pixel of your screen? <span className="text-emerald-400 font-bold">we do.</span>
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  today, we're force-fed an endless stream of sequels, remakes, and "live services" designed not to entertain, but to extract maximum value from players. creativity isn't just dying - it's being actively suppressed.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  the gatekeepers claim they're "protecting quality." <span className="text-red-400 font-semibold">bullshit</span>. they're protecting their monopoly on creation. they're terrified of what happens when everyone can create.
                </motion.p>
              </motion.div>
            </section>

            <section id="the-fix-degs" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                the fix: degs
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  we're not just building another game studio. we're building the <span className="text-emerald-400 font-bold">last one you'll ever see.</span> A decentralized platform where AI meets creativity, where anyone can turn their vision into reality without writing a single line of code.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  imagine speaking your game idea into existence. no coding. no publishers. no bullshit. that's not science fiction - <span className="text-white font-semibold">that's what we've built</span>.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  this is where $DEGS powers a new gaming economy, where creators truly own their destiny, and where players fuel real value.
                </motion.p>
                <motion.div 
                  className="bg-white/5 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                > 
                  <p className="text-white/80 leading-relaxed">
                    we're revolutionizing how creators get paid:
                    <span className="block mt-4 text-2xl font-semibold text-emerald-400">30% to token holders</span>
                    <span className="block mt-2 text-xl font-semibold text-emerald-400/80">70% to creators</span>
                    <span className="block mt-4">transparent. immutable. fair.</span>
                    <span className="block mt-2 text-white/60">no more predatory publisher deals. no more revenue black boxes.</span>
                  </p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  this isn't just about making games. it's about breaking the chains that have held back creativity for decades. it's about giving power back to creators and players. it's about making gaming what it was always meant to be: <span className="text-emerald-400 font-bold">a universe of infinite possibilities</span>.
                </motion.p>
              </motion.div>
            </section>
          </>
        )}

        {/* Why Now Section */}
        {activeSection === 'why-now' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Why Now
            </motion.h1>
            
            <motion.div 
              className="mb-16 w-full"
              variants={fadeInUp}
            >
              <div className="flex justify-center">
                <Tweet id="1861801046949191686" />
              </div>
            </motion.div>

            <motion.div 
              className="space-y-8 text-lg"
              variants={staggerChildren}
            >
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're standing at the precipice of a <span className="text-emerald-400 font-bold">revolution</span>. in mere months, AI will surpass human coding capabilities. the walls between imagination and creation are about to crumble. and when they do, an explosion of creativity will follow.
              </motion.p>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                the old guards see this coming. they're terrified. <span className="text-white font-semibold">they should be</span>. when anyone can create a game by simply describing it, their monopoly on creation dies. their armies of developers become obsolete. their control over gaming ends.
              </motion.p>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're starting with casual mobile games because that's what current AI can handle. but make no mistake - this is just the beginning. as AI evolves, so will the complexity of games that can be created. from simple puzzles to open-world epics, all through the power of your voice.
              </motion.p>
              <motion.div 
                className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-white mb-4">the next gaming revolution won't come from a big studio</p>
                <p className="text-white/80">it will come from millions of creators, finally unleashed. and they'll need a platform. a home. a place where creativity flows freely and rewards flow fairly.</p>
              </motion.div>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                that's what we're building. that's why we're building it now. because the future of gaming isn't about better graphics or bigger budgets. <span className="text-emerald-400 font-bold">it's about democratizing creation itself</span>.
              </motion.p>
            </motion.div>
          </>
        )}

        {/* Ecosystem Section */}
        {activeSection === 'ecosystem' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Ecosystem
            </motion.h1>
            
            <section id="platform-overview" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                overview
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  degs isn't just a platform - it's an <span className="text-emerald-400 font-bold">ecosystem designed for the AI-powered future</span> of game creation. at its core is a revolutionary AI engine that turns spoken words into playable games. no coding required. no technical knowledge needed. just pure creativity.
                </motion.p>
                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">single file. instant deployment.</p>
                  <p className="text-white/80">every game is a single file. HTML5. React. whatever works best. deployed instantly to our decentralized network. no app stores. no approval processes. no waiting. create, publish, and start earning in minutes.</p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  our monetization system is built on <span className="text-emerald-400 font-bold">blockchain technology</span>. transparent. immutable. fair. decentralized ads run on smart contracts, ensuring maximum revenue for creators. in-app purchases flow directly through the ecosystem, with creators getting the lion's share.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  MetaMask integration ensures <span className="text-emerald-400 font-bold">secure, instant payments</span>. no paperwork. no delays. no bullshit. create something amazing, and you'll be rewarded immediately. that's how it should have always been.
                </motion.p>
              </motion.div>
            </section>

            <section id="game-creation" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                game creation
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  creating a game on degs is as natural as having a conversation. describe your vision - the gameplay, the aesthetics, the feel. our AI understands context, nuance, and intent. it doesn't just follow instructions - it <span className="text-emerald-400 font-bold">collaborates with you</span>.
                </motion.p>
                <motion.div 
                  className="bg-white/5 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-white/80 leading-relaxed">
                    the process is simple:
                    <span className="block mt-4 text-xl text-emerald-400 font-semibold">1. describe your idea to the AI</span>
                    <span className="block mt-2 text-xl text-emerald-400 font-semibold">2. the platform generates your game</span>
                    <span className="block mt-2 text-xl text-emerald-400 font-semibold">3. improve through iteration</span>
                    <span className="block mt-2 text-xl text-emerald-400 font-semibold">4. publish and monetize</span>
                  </p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  iteration is <span className="text-emerald-400 font-bold">instant</span>. don't like something? say it. want to change the mechanics? describe it. the AI adapts in real-time, letting you perfect your vision without technical constraints. this is game development at the speed of thought.
                </motion.p>
              </motion.div>
            </section>
          </>
        )}

        {/* $DEGS Section */}
        {activeSection === 'degs-token' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              $DEGS Token
            </motion.h1>
            
            <section id="overview" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                overview
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">be part of the revolution</p>
                  <p className="text-white/80 mb-8">$DEGS isn't just another token. it's the fuel that powers our ecosystem, aligning the interests of creators, players, and holders through automated and transparent mechanics.</p>
                  <button 
                    onClick={() => window.open('https://springboard.pancakeswap.finance/base/token/0x0ae8a62393967293ea2a44775110c5a4eb423e8e', '_blank')}
                    className="bg-emerald-500 hover:bg-emerald-400 text-black font-bold text-xl px-8 py-4 rounded-2xl transition-all hover:scale-105"
                  >
                    join the fair launch
                  </button>
                </motion.div>

              </motion.div>
            </section>

            <section id="tokenomics" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                tokenomics
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold mb-4">1 Billion $DEGS</p>
                  <div className="space-y-4">
                    <p className="text-white/80">
                      <span className="text-emerald-400 font-semibold">90%</span> fair launch
                      <span className="block ml-4 text-sm">- 75% public sale through PancakeSwap SpringBoard</span>
                      <span className="block ml-4 text-sm">- 15% liquidity pool, locked forever</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400 font-semibold">10%</span> marketing & partnerships
                      <span className="block ml-4 text-sm">3-month linear vesting</span>
                      <span className="block ml-4 text-sm">critical for first 3 months of growth</span>
                    </p>
                  </div>
                </motion.div>

                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">revenue sharing</p>
                  <div className="space-y-4">
                    <p className="text-white/80">
                      <span className="text-emerald-400 font-semibold">70%</span> to game creators
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400 font-semibold">30%</span> to token holders
                    </p>
                    <p className="text-white/80 mt-4">
                      powers all platform features:
                      <span className="block ml-4">• in-app purchases</span>
                      <span className="block ml-4">• advertisements</span>
                    </p>
                  </div>
                </motion.div>

                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  target raise: <span className="text-emerald-400 font-bold">100 ETH</span>. fair launch through PancakeSwap SpringBoard. <span className="text-emerald-400 font-bold">no team allocation. no presale.</span> maximum fairness.
                </motion.p>
              </motion.div>
            </section>

            <section id="springboard" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                springboard
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  when it comes to launching tokens, there are two paths: build everything from scratch and pray you didn't miss anything, or <span className="text-emerald-400 font-bold">stand on the shoulders of giants</span>.
                </motion.p>

                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">we work smarter not harder</p>
                  <p className="text-white/80">PancakeSwap isn't just another DEX - it's the backbone of decentralized finance, processing millions in volume daily. their SpringBoard platform is a masterpiece of automation and security. every contract is audited. every mechanism is tested. every promise is hardcoded by them, not us.</p>
                </motion.div>

                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  SpringBoard handles everything: <span className="text-emerald-400 font-bold">token creation, fair launch, liquidity locking</span>. no human intervention. no backdoors possible. the code is law, and the law is fair. we couldn't rugpull even if we wanted to - and that's exactly how we like it.
                </motion.p>

                <motion.div 
                  className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">automatic. transparent. secure.</p>
                  <div className="space-y-4 text-white/80">
                    <p>• token creation with verified, audited contracts</p>
                    <p>• fair launch with bonding curve mechanics</p>
                    <p>• automatic liquidity locking through GoPlusLabs forever</p>
                    <p>• instant PancakeSwap v3 integration</p>
                  </div>
                </motion.div>

                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  we're not here to reinvent the wheel - we're here to <span className="text-emerald-400 font-bold">revolutionize gaming</span>. by leveraging SpringBoard's battle-tested infrastructure, we can focus on what matters: building the future of game creation.
                </motion.p>

                <motion.div 
                  className="bg-gradient-to-r from-amber-500/10 to-transparent rounded-3xl border border-amber-500/20 p-8"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-amber-400 mb-4">skin in the game</p>
                  <div className="space-y-4 text-white/80">
                    <p>we've placed a 1.5 ETH order at launch - same price, same conditions as everyone else.</p>
                    <p>these funds will be automatically locked in the liquidity pool forever, just like all other raised ETH.</p>
                    <p>no free allocation. no special treatment. we're in this together.</p>
                  </div>
                </motion.div>
              </motion.div>
            </section>
          </>
        )}

        {/* Closing Thoughts Section */}
        {activeSection === 'closing-thoughts' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              ad astra
            </motion.h1>
            <motion.div 
              className="space-y-8 text-lg"
              variants={staggerChildren}
            >
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're not just dreaming about the future of gaming - we're <span className="text-emerald-400 font-bold">building it</span>. this manifesto is just a glimpse of what we have planned.
              </motion.p>
              
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                the tools we're creating will <span className="text-emerald-400 font-bold">empower everyone</span> to bring their gaming visions to life. no more gatekeepers. no more barriers. just pure creativity unleashed.
              </motion.p>

              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                this is where it all begins - the democratization of game creation, powered by AI and fueled by our community.
              </motion.p>

              <motion.div 
                className="bg-gradient-to-r from-emerald-500/10 to-transparent rounded-3xl border border-emerald-500/20 p-8"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-white mb-8">ready to be part of the revolution?</p>
                <button 
                  onClick={() => window.open('https://springboard.pancakeswap.finance/base/token/0x0ae8a62393967293ea2a44775110c5a4eb423e8e', '_blank')}
                  className="bg-emerald-500 hover:bg-emerald-400 text-black font-bold text-xl px-8 py-4 rounded-2xl transition-all hover:scale-105"
                >
                  join the fair launch
                </button>
              </motion.div>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default ManifestoContent; 