import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { navigateExternal } from '../utils/navigation';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
        />
      )}
      
      {/* Sidebar */}
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: isOpen ? 0 : '-100%' }}
        transition={{ type: 'tween', duration: 0.3 }}
        className="fixed left-0 top-0 h-full w-64 bg-black border-r border-gray-800 z-50 p-6"
      >
        <div className="flex flex-col space-y-6">
          <Link to="/" className="text-2xl font-bold" onClick={onClose}>degs</Link>
          <div className="flex flex-col space-y-4">
            <Link to="/ecosystem" className="nav-link" onClick={onClose}>ecosystem</Link>
            <Link to="/path" className="nav-link" onClick={onClose}>path</Link>
            <Link to="/token" className="nav-link" onClick={onClose}>$degs</Link>
            <a 
              href="https://app.degs.ai/token"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
              onClick={onClose}
            >
              buy
            </a>
            <Link to="/socials" className="nav-link" onClick={onClose}>socials</Link>
            <Link 
              to="/manifesto" 
              className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors text-center" 
              onClick={onClose}
            >
              manifesto
            </Link>
            <button 
              onClick={() => {
                onClose();
                navigateExternal('https://app.degs.ai');
              }}
              className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors w-full text-center"
            >
              launch app
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar; 