import React, { useState } from 'react';

interface Section {
  id: string;
  title: string;
  subsections?: { id: string; title: string; }[];
}

const sections: Section[] = [
  {
    id: 'manifesto',
    title: 'manifesto',
    subsections: [
      { id: 'the-problem-gaming-is-broken', title: 'gaming is broken' },
      { id: 'the-fix-degs', title: 'the fix: degs' }
    ]
  },
  {
    id: 'why-now',
    title: 'why now'
  },
  {
    id: 'ecosystem',
    title: 'ecosystem',
    subsections: [
      { id: 'platform-overview', title: 'overview' },
      { id: 'game-creation', title: 'game creation' }
    ]
  },
  {
    id: 'degs-token',
    title: '$degs',
    subsections: [
      { id: 'overview', title: 'overview' },
      { id: 'tokenomics', title: 'tokenomics' },
      { id: 'springboard', title: 'springboard' }
    ]
  },
  {
    id: 'closing-thoughts',
    title: 'per aspera ad astra'
  }
];

interface ManifestoSidebarProps {
  activeSection: string;
  activeSubSection: string;
  onSectionChange: (section: string) => void;
  onSubSectionChange: (subsection: string) => void;
}

const ManifestoSidebar = ({
  activeSection,
  activeSubSection,
  onSectionChange,
  onSubSectionChange
}: ManifestoSidebarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="hidden md:block w-64 h-[calc(100vh-4rem)] overflow-y-auto fixed bg-black/80 backdrop-blur-sm border-r border-white/10">
        <div className="p-6">
          <h2 className="text-xl font-bold mb-6">Contents</h2>
          <nav>
            {sections.map((section) => (
              <div key={section.id} className="mb-4">
                <button
                  onClick={() => onSectionChange(section.id)}
                  className={`text-left w-full py-2 hover:text-gray-300 transition-colors duration-200 ${
                    activeSection === section.id ? 'text-white font-bold' : 'text-gray-400'
                  }`}
                >
                  {section.title}
                </button>
                
                {section.subsections && (
                  <div className="ml-4 border-l border-white/10">
                    {section.subsections.map((subsection) => (
                      <button
                        key={subsection.id}
                        onClick={() => {
                          onSectionChange(section.id);
                          onSubSectionChange(subsection.id);
                        }}
                        className={`text-left w-full py-2 pl-4 hover:text-gray-300 transition-colors duration-200 ${
                          activeSubSection === subsection.id ? 'text-white font-bold' : 'text-gray-400'
                        }`}
                      >
                        {subsection.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>

      {/* Mobile Navigation Bar */}
      <div className="md:hidden fixed top-16 left-0 right-0 z-40 bg-black border-b border-white/10">
        <button
          onClick={toggleSidebar}
          className="w-full p-4 flex justify-between items-center text-white"
        >
          <span className="font-bold">Contents</span>
          <svg
            className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="absolute top-full left-0 right-0 bg-black border-b border-white/10">
            <div className="p-4">
              {sections.map((section) => (
                <button
                  key={section.id}
                  onClick={() => {
                    onSectionChange(section.id);
                    setIsOpen(false);
                  }}
                  className={`w-full text-left py-3 ${
                    activeSection === section.id ? 'text-white font-bold' : 'text-gray-400'
                  }`}
                >
                  {section.title}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ManifestoSidebar; 